$(function(){
    var top_H=$('.index > .top').outerHeight(true);
    $('.index > .main').css({'margin-top': (-top_H) +'px'});

    $(window).on('scroll',function(){
        var kv_H=$('.kv').outerHeight(true);
        var scroll_d=$(this).scrollTop();
        if(scroll_d>kv_H) {
            $('body').addClass('overKV');
            $('.index > .main').css({'margin-top': 0});
        } else {
            $('body').removeClass('overKV');
            $('.index > .main').css({'margin-top': (-top_H) +'px'});
        }
    }).trigger('scroll');

    var window_W=$(window).outerWidth(true);
    if(window_W<=768) {
        $('.quickMenu [data-id="collapse-switch"]').on('click touched',function(){
            $(this).closest('li').toggleClass('on');
            $(this).next('[data-id="collapse-content"]').slideToggle(300);
        });
    };

    var $eventSlider = $('[data-slick-slider="event"]');
    $eventSlider.on('init', function(event, slick){
        $(this).closest('.carousel').css({'visibility':'visible'});
        $(this).find('.recommend__text .title').addClass('clamp--2line');
    }).slick({
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 3,
        arrows: false,
        fade: false,
        dots: false,
        rows: 0,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    dots: true,
                    dotsClass: 'slides__dots slides__dots--bottom'
                }
            }
        ]
    }).on('breakpoint',function() {
        setTimeout(function() {
            $(this).find('.recommend__text .title').removeClass('clamp--2line').addClass('clamp--2line');
        }, 100);
    });

    var $featureSlider = $('[data-slick-slider="feature"]');
    $featureSlider.on('init', function(event, slick){
        $(this).closest('.carousel').css({'visibility':'visible'});
    }).slick({
        infinite: true,
        rows: 0,
        slidesToShow: 3,
        slidesToScroll: 3,
        fade: false,
        dots: false,
        prevArrow: '<button class="slick-prev slides__arrow slides__arrow--prev" aria-label="Previous" type="button"></button>',
        nextArrow: '<button class="slick-next slides__arrow slides__arrow--next" aria-label="Next" type="button"></button>',
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                    dots: false,
                    centerPadding: '60px',
                }
            }
        ]
    });

    var $kvSlider = $('[data-slick-slider="kv"]');
    $kvSlider.on('init', function(event, slick){
        $(this).closest('.carousel').css({'visibility':'visible'});
    }).slick({
        infinite: true,
        rows: 0,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        arrows: false,
        dots: false,
        autoplay: true,
        autoplaySpeed: 5000,
    });
});
